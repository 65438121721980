import { Spin, Table } from "antd";
import { map } from "lodash";
import get from "lodash.get";
import React, { useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import FaqDrawer from "../components/FaqDrawer";
import ModalImage from "react-modal-image";

import {
  binanceTrxImge,
  itsTrxImg
} from "../../../images";

const columns = [
  {
    title: "Ez Level",
    dataIndex: "ezLevel",
    key: "ezLevel",
  },
  {
    title: "No. of Direct Referees",
    dataIndex: "directReferee",
    key: "directReferee",
  },
];

const PAGESIZE = 10;
// markup
const VideosListingPage = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedType, setSelectedType] = useState({});

  const general_questions = [
    {
      questions: t("whatisIts", sourceKey.faq),
      answer: () => {
        return <div>{t("knownAs", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("earnProfit", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <div>
              <span>{t("earn1", sourceKey.faq)}</span>
              <div className="pl-8">
                <ol className="list-decimal">
                  <li>{t("earn2", sourceKey.faq)}</li>
                  <li>{t("earn3", sourceKey.faq)}</li>
                </ol>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("takeNoteReg", sourceKey.faq),
      answer: () => {
        return <div>{t("takeNoteAns", sourceKey.faq)}</div>;
      },
    },
  ];

  const registration_questions = [
    {
      questions: t("howReg", sourceKey.faq),
      answer: () => {
        return (
          <div>
            {t("regAns1", sourceKey.faq)}{" "}
            <a
              href="https://indoits.com"
              className="underline text-blue-700"
              target="_blank"
            >
              {t("myits", sourceKey.faq)}
            </a>{" "}
            {t("regAns2", sourceKey.faq)}
            <a
              href="https://www.youtube.com/watch?v=y_xaqjM-y60&ab_channel=EzSight"
              className="underline text-blue-700"
              target="_blank"
            >
              {t("youtubeVideo", sourceKey.faq)}
            </a>
          </div>
        );
      },
    },
    {
      questions: t("whyBindApi", sourceKey.faq),
      answer: () => {
        return <div>{t("whyBindAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("howBindApi", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <div>
              <span>{t("howBindApiAns1", sourceKey.faq)}</span>
              <div className="pl-8">
                <ol className="list-decimal">
                  <li>{t("howBindApiAns2", sourceKey.faq)}</li>
                  <li>{t("howBindApiAns3", sourceKey.faq)}</li>
                  <li>{t("howBindApiAns4", sourceKey.faq)}</li>
                  <li>{t("howBindApiAns5", sourceKey.faq)}</li>
                  <li> {t("howBindApiAns6", sourceKey.faq)}</li>
                  <li>{t("howBindApiAns7", sourceKey.faq)}</li>
                  {/* <li>
                  You’ll then be awarded with 7 free active days and 15 free
                  VSDT as a beginner reward.
                </li> */}
                </ol>
              </div>
              <span>
                {t("howBindApiAns8", sourceKey.faq)}{" "}
                <a
                  href="https://www.youtube.com/watch?v=zZcCRfIr4_U&ab_channel=EzSight"
                  className="underline text-blue-700"
                  target="_blank"
                >
                  {t("howBindApiAns9", sourceKey.faq)}
                </a>{" "}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("invalidApi", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <span>{t("invalidApiAns1", sourceKey.faq)}</span>
            <div className="pl-8">
              <ol className="list-decimal">
                <li>
                  {t("invalidApiAns2", sourceKey.faq)}
                  <ol className="list-disc pl-4">
                    <li>{t("invalidApiAns3", sourceKey.faq)}</li>
                  </ol>
                </li>
                <li>{t("invalidApiAns4", sourceKey.faq)}</li>
                <li>{t("invalidApiAns5", sourceKey.faq)}</li>
              </ol>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("howManyAccount", sourceKey.faq),
      answer: () => {
        return <div>{t("howManyAccountAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("api90D", sourceKey.faq),
      answer: () => {
        return <div>{t("api90DAns", sourceKey.faq)}</div>;
      },
    },
  ];

  const asset_questions = [
    {
      questions: t("whatUSDT", sourceKey.faq),
      answer: () => {
        return <div>{t("whatUSDTAns", sourceKey.faq)}</div>;
      },
    },
    // {
    //   questions: "What is VST in Asia EZ Sight?",
    //   answer: () => {
    //     return (
    //       <div>
    //         VST is also our coin on Asia EZ Sight. It’s the asset that has been
    //         awarded to you to create orders with our AI Trading robot and you are
    //         not allowed to withdraw or transfer your VST amount.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "What is Voucher in Asia EZ Sight?",
    //   answer: () => {
    //     return (
    //       <div>
    //         Voucher is a coupon given to you for a 20% discount deduction from the
    //         service charge we’ve issued to you with the use of AI trading robots.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "What is an active day?",
    //   answer: () => {
    //     return (
    //       <div>
    //         Active day is the number of days left you’re allowed to use our
    //         service. Asia EZ Sight is run by subscription business model, which
    //         means that you’re required to subscribe to our service by purchasing
    //         our activation code .
    //       </div>
    //     );
    //   },
    // },
    {
      questions: t("topUpUsdt", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <span>{t("topUpUsdtAns1", sourceKey.faq)}</span>
            <div className="pl-8">
              <ol className="list-decimal">
                <li> {t("topUpUsdtAns2", sourceKey.faq)}</li>
                <li>{t("topUpUsdtAns3", sourceKey.faq)}</li>
                <li>{t("topUpUsdtAns4", sourceKey.faq)}</li>
                <li>{t("topUpUsdtAns5", sourceKey.faq)}</li>
              </ol>
            </div>
          </div>
        );
      },
    },
    // {
    //   questions: "How can I top up my active days?",
    //   answer: () => {
    //     return (
    //       <div>
    //         <span>
    //           The only way to top up your active days is to subscribe to our
    //           package. You may follow the steps below to top up your active days:
    //         </span>
    //         <div className="pl-8">
    //           <ol className="list-decimal">
    //             <li>Go to “Asset” and choose “Activation Code”</li>
    //             <li>You will then see a list of packages.</li>
    //             <li>
    //               Choose a package you prefer. (You may check out if there’s any
    //               seasonal limited promotion or free gift in the package details)
    //             </li>
    //             <li>Purchase it with USDT.</li>
    //           </ol>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      questions: t("transferUsdt", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <span>{t("transferUsdtAns1", sourceKey.faq)}</span>
            <div className="pl-8">
              <ol className="list-decimal">
                <li> {t("transferUsdtAns2", sourceKey.faq)} </li>
                <li>{t("transferUsdtAns3", sourceKey.faq)}</li>
                <li>{t("transferUsdtAns4", sourceKey.faq)}</li>
                <li>{t("transferUsdtAns5", sourceKey.faq)}</li>
                <li>{t("transferUsdtAns6", sourceKey.faq)}</li>
                <li>{t("transferUsdtAns7", sourceKey.faq)}</li>
                <li>{t("transferUsdtAns8", sourceKey.faq)}</li>
              </ol>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("withdrawUsdt", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <span>{t("withdrawUsdtAns1", sourceKey.faq)}</span>
            <div className="pl-8">
              <ol className="list-decimal">
                <li>{t("withdrawUsdtAns2", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns3", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns4", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns5", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns6", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns7", sourceKey.faq)}</li>
                <li>{t("withdrawUsdtAns8", sourceKey.faq)}</li>
              </ol>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("submitRequest", sourceKey.faq),
      answer: () => {
        return <div>{t("submitRequestAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("pnlRecord", sourceKey.faq),
      answer: ()=> {
        return (
          <div>
            <span>{t("pnlRecord2", sourceKey.faq)}</span>
            <div>{t("pnlRecord3", sourceKey.faq)}</div>
            <div className="pl-8">
              <ol className="list-disc">
                <li>
                  <strong>
                    {t("pnlRecordAns1", sourceKey.faq)}
                  </strong>
                  <ol className="list-decimal pl-6">
                    <li>{t("pnlRecordAns1.1", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns1.2", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns1.3", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns1.4", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns1.5", sourceKey.faq)}</li>
                  </ol>
                </li>
                <li>
                  <strong>
                    {t("pnlRecordAns2", sourceKey.faq)}
                  </strong>
                  <ol className="list-decimal pl-6">
                    <li>{t("pnlRecordAns2.1", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns2.2", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns2.3", sourceKey.faq)}</li>
                    <li>{t("pnlRecordAns2.4", sourceKey.faq)}</li>
                    <li>
                      <strong>{t("pnlRecordAns2.5", sourceKey.faq)}</strong>
                      <ol className="list-disc pl-4">
                        <li>{t("pnlRecordAns2.6", sourceKey.faq)}<br/>
                            <ModalImage small={binanceTrxImge} large={binanceTrxImge} alt="Binance Transaction Record" />
                        </li>
                        <li>{t("pnlRecordAns2.7", sourceKey.faq)}<br/>
                            <ModalImage small={itsTrxImg} large={itsTrxImg} alt="ITS Transaction Record" />
                        </li>
                        <li>{t("pnlRecordAns2.8", sourceKey.faq)}<br/></li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        );
      },
    }
    // {
    //   questions:
    //     "What would happen if my account is lacking USDT while the robot is running?",
    //   answer: () => {
    //     return (
    //       <div>
    //         You will receive an alert informing you that your account is lacking
    //         USDT and remind you to top up your asset. If there’s no action being
    //         taken and your USDT has become zero, your robot will then stop placing
    //         and selling any orders for you until you have topped up your USDT.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions:
    //     "What would happen if my account is lacking active days while the robot is running?",
    //   answer: () => {
    //     return (
    //       <div>
    //         You will receive an alert informing you that your account is lacking
    //         active days and remind you to top up your active days. If there’s no
    //         action being taken and you have no active day left, your robot will
    //         then stop placing and selling any orders for 24 hours. If there aren't
    //         any actions taken after 24 hours, all of your robots will force
    //         liquidize all of the current stocks they are holding.
    //       </div>
    //     );
    //   },
    // },
  ];

  const robot_questions = [
    // {
    //   questions: "How can I start using the robot?",
    //   answer: () => {
    //     return (
    //       <div>
    //         You may start using our AI trading robot once you have active days and
    //         a minimum of 10 USDT.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "How much will the robot charge from the profit?",
    //   answer: () => {
    //     return (
    //       <div>
    //         Our AI Trading Robot will charge 20% out of the total profit you have
    //         earned from each particular trade. You may check the transaction of
    //         your service charge on the bottom part of your asset main tab.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions:
    //     "How many types of strategies are available on Asia EZSight currently?",
    //   answer: () => {
    //     return (
    //       <div>
    //         So far we’ve invented one main High Frequency strategy and there are
    //         two sub-strategies which are HF 1 and HF 3 under this HF strategy.
    //       </div>
    //     );
    //   },
    // },
    {
      questions: t("robotCreate", sourceKey.faq),
      answer: () => {
        return <div>{t("robotCreateAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("invsetRobot", sourceKey.faq),
      answer: () => {
        return <div>{t("investRobotAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("stategyRobot", sourceKey.faq),
      answer: () => {
        return <div>{t("stategyRobotAns", sourceKey.faq)}</div>;
      },
    },
    // {
    //   questions: "What is HF 1 and when should I create a HF 1 robot?",
    //   answer: () => {
    //     return (
    //       <div>
    //         It’s a grid trading strategy with customisable maximum price setting,
    //         suitable for uptrend and sideways.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "For HF 1, how is the fund allocated?",
    //   answer: () => {
    //     return (
    //       <div>
    //         The fund is allocated according to the numbers of grid selected by the
    //         users.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "Is there any minimum amount required to create a HF1 robot?",
    //   answer: () => {
    //     return (
    //       <div>Yes, the minimum amount required is 150 USDT for 10 grids.</div>
    //     );
    //   },
    // },
    // {
    //   questions: "What’s the most unique feature available in HF 1?",
    //   answer: () => {
    //     return (
    //       <div>
    //         <span>
    //           The most unique feature available in HF1 is the once and loop
    //           setting.
    //         </span>
    //         <div className="pl-8">
    //           <ol className="list-disc">
    //             <li>
    //               <strong>Once</strong>: It’s specially designed for users to
    //               trade for a certain period only. Once the first ever stock has
    //               been sold out, the robot will then stop creating any new orders.
    //             </li>
    //             <li>
    //               <strong>Loop</strong>: This is the setting where the robots will
    //               stop placing new orders if it’s above the max price set by the
    //               user. If the user holds any positions, the robot will still
    //               continue selling.
    //             </li>
    //           </ol>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "What is HF 3 and when should I create a HF 3 robot?",
    //   answer: () => {
    //     return (
    //       <div>
    //         HF 3 is an infinity grid trading strategy, long term investment with
    //         maximum flexibility.
    //       </div>
    //     );
    //   },
    // },
    {
      questions: t("minAmount", sourceKey.faq),
      answer: () => {
        return <div>{t("minAmountAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("uniqueFeatures", sourceKey.faq),
      answer: () => {
        return (
          <div>
            <span>{t("uniqueFeaturesAns1", sourceKey.faq)}</span>
            <div className="pl-8">
              <ol className="list-disc">
                <li>
                  <strong>{t("uniqueFeaturesAns2", sourceKey.faq)}</strong>
                  {t("uniqueFeaturesAns3", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("uniqueFeaturesAns4", sourceKey.faq)}</strong>
                  {t("uniqueFeaturesAns5", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("uniqueFeaturesAns6", sourceKey.faq)}</strong>
                  {t("uniqueFeaturesAns7", sourceKey.faq)}
                </li>
              </ol>
            </div>
          </div>
        );
      },
    },
    {
      questions: t("compoundQues", sourceKey.faq),
      answer: ()=> {
        return(
          <div>
            <span>{t("compoundTitle", sourceKey.faq)}</span>
            <div>
              <strong>{t("compoundFormula", sourceKey.faq)}</strong>
            </div>
            <div style={{fontSize:"11px"}}>
              {t("compoundFormula2", sourceKey.faq)}
            </div>
            <div>
              <strong>{t("compoundAns", sourceKey.faq)}</strong>
            </div>
            <div>{t("compoundAns2", sourceKey.faq)}</div>
            <div className="pl-8">
              <ol className="list-disc">
                <li>
                  <strong>{t("compoundAns3", sourceKey.faq)}</strong>
                  {t("compoundAns4", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("compoundAns5", sourceKey.faq)}</strong>
                  {t("compoundAns6", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("compoundAns7", sourceKey.faq)}</strong>
                  {t("compoundAns8", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("compoundAns9", sourceKey.faq)}</strong>
                  {t("compoundAns10", sourceKey.faq)}
                </li>
                <li>
                  <strong>{t("compoundAns11", sourceKey.faq)}</strong>
                  {t("compoundAns12", sourceKey.faq)}
                </li>
              </ol>
            </div>
            <div>
              {t("compoundAns13", sourceKey.faq)}
            </div>
            <div>
              <strong>{t("compoundAns14", sourceKey.faq)}</strong>
            </div>
            <div style={{fontSize: '11px'}}>
              {t("compoundAns15", sourceKey.faq)}
            </div>
          </div>
        );
      },
    },
  ];

  const refer_question = [
    {
      questions: t("referFriend", sourceKey.faq),
      answer: () => {
        return <div>{t("referFriendAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("referees", sourceKey.faq),
      answer: () => {
        return <div>{t("refereesAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("benefitRefer", sourceKey.faq),
      answer: () => {
        return <div>{t("benefitReferAns", sourceKey.faq)}</div>;
      },
    },
  ];

  const level_questions = [
    {
      questions: "What is EZ Level?",
      answer: () => {
        return (
          <div>
            EZ Level is basically your level on our Asia EZ Sight app. The
            higher level you have unlocked, the more rewards you will be
            qualified to.
          </div>
        );
      },
    },
    {
      questions: "How do I get a higher EZ Level?",
      answer: () => {
        return (
          <div>
            In order to get a higher EZ Level, you are required to get more
            users to be your referee.
          </div>
        );
      },
    },
    {
      questions:
        "What’s the minimum number of referees needed for each EZ Level?",
      answer: () => {
        return (
          <div>
            Please refer to the table below for the minimum numbers of referee
            required for each level:
            <Table
              className="mt-4"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              bordered
            />
          </div>
        );
      },
    },
  ];

  const reward_list = [
    {
      questions: t("earnRewards", sourceKey.faq),
      answer: () => {
        return <div>{t("earnRewardsAns", sourceKey.faq)}</div>;
      },
    },
    // {
    //   questions: "What are the types of rewards available and what do they mean?",
    //   answer: () => {
    //     return (
    //       <div>
    //         <span>
    //           Since HF 3 is well suitable for long term investment, we’ve invented
    //           some AI smart feature to maximize your profit earning:
    //         </span>
    //         <div className="pl-8">
    //           <ol className="list-disc">
    //             <li>
    //               <strong>Community Rewards</strong>: <br />
    //               You will be rewarded once any of your direct referees or group
    //               referees has subscribed to our activation code package or has
    //               gained profit from the robo-trading system.
    //             </li>
    //             <li>
    //               <strong>ITS Point Pool Rewards</strong>: <br />
    //               An extra bonus for those who have achieved ITS Level 3 or above.
    //               The qualified users will be able to get a portion from the ITS
    //               Point Pool.
    //             </li>
    //           </ol>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "For community rewards, are there any qualifications needed?",
    //   answer: () => {
    //     return (
    //       <div>
    //         Yes, you are required to have activation day and an active robot in
    //         order to be qualified for our community rewards.
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions: "How to be qualified for ITS Point Pool rewards?",
    //   answer: () => {
    //     return (
    //       <div>
    //         <span>
    //           First, you’ll need to unlock ITS Level 3 or above. To be able to get
    //           the rewards, the system will check if you have hit the qualification
    //           on each day and here are the four criterias you need to hit in order
    //           to be qualified:
    //         </span>
    //         <div className="pl-8">
    //           <ol className="list-decimal">
    //             <li>Have active day(s).</li>
    //             <li>Have active robot(s).</li>
    //             <li>
    //               Your minimum number of referees (based on your ITS level) have
    //               active day(s).
    //             </li>
    //             <li>
    //               Your minimum number of referees (based on your ITS level) have
    //               active robot(s).
    //             </li>
    //           </ol>
    //         </div>
    //         <span>
    //           After calculating the qualified days of the month, the system will
    //           then use the percentage of the qualified days to multiply by the
    //           total points to get the final awarded amount.
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   questions:
    //     "When will ITS Point Pool rewards be distributed to the qualified users?",
    //   answer: () => {
    //     return (
    //       <div>
    //         At 12:01am on the first day of the month, the system will
    //         automatically distribute the rewards to all the qualified users.
    //       </div>
    //     );
    //   },
    // },
  ];

  const user_questions = [
    {
      questions: t("forgotPassword", sourceKey.faq),
      answer: () => {
        return <div>{t("forgotPasswordAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("changeEmail", sourceKey.faq),
      answer: () => {
        return <div>{t("changeEmailAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("changePassword", sourceKey.faq),
      answer: () => {
        return <div>{t("changePasswordAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("changeUsername", sourceKey.faq),
      answer: () => {
        return <div>{t("changeUsernameAns", sourceKey.faq)}</div>;
      },
    },
  ];

  const customerSupport = [
    {
      questions: t("customerSupport", sourceKey.faq),
      answer: () => {
        return <div>{t("customerSupportAns", sourceKey.faq)}</div>;
      },
    },
    {
      questions: t("howLong", sourceKey.faq),
      answer: () => {
        return <div>{t("howLongAns", sourceKey.faq)}</div>;
      },
    },
  ];

  const questions_types = [
    {
      type: t("inGeneral", sourceKey.faq),
      dataSource: general_questions,
    },
    {
      type: t("aboutReg", sourceKey.faq),
      dataSource: registration_questions,
    },
    {
      type: t("aboutAsset", sourceKey.faq),
      dataSource: asset_questions,
    },
    {
      type: t("aboutRobot", sourceKey.faq),
      dataSource: robot_questions,
    },
    {
      type: t("aboutRefering", sourceKey.faq),
      dataSource: refer_question,
    },
    // {
    //   type: "About EZ Level",
    //   dataSource: level_questions,
    // },
    {
      type: t("aboutRewards", sourceKey.faq),
      dataSource: reward_list,
    },
    {
      type: t("aboutUserProfile", sourceKey.faq),
      dataSource: user_questions,
    },
    {
      type: t("aboutCS", sourceKey.faq),
      dataSource: customerSupport,
    },
  ];

  //   useEffect(() => {
  //     getData((page - 1) * PAGESIZE);
  //   }, [page]);

  //   useEffect(() => {
  //     getData((1 - 1) * PAGESIZE);
  //   }, [languageType]);

  function getData(skip) {
    setLoading(true);
    // console.log("getData");
    // let filteredGroup = {};
    // if (!isNaN(parseInt(languageType))) {
    //   filteredGroup.languageType = parseInt(languageType);
    // }

    // getVideos(PAGESIZE, skip, { ...filteredGroup })
    //   .then((res) => {
    //     let data = get(res, "data");
    //     if (!isEmpty(data)) {
    //       let temp_data = cloneDeep(dataSource);
    //       forEach(data, (item, index) => {
    //         temp_data.push(item);
    //       });
    //       setDataSource(temp_data);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   });
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {/* FAQ{" "} */}
                {t("faq")}
                {/* <MediaQuery maxWidth={440}>
                  <br />
                </MediaQuery>
                常问问题 */}
              </div>
            }
            showBack={true}
          >
            <div
              className=""
              // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
            >
              {map(questions_types, (item, index) => {
                return (
                  <div
                    className="lightgreybox-bg-color px-4 py-2.5 m-4 rounded-3xl cursor-pointer"
                    onClick={() => {
                      setVisible(true);
                      setSelectedType(item);
                    }}
                  >
                    {get(item, "type")}
                  </div>
                );
              })}

              {/* <Scrollbars
                // className="mt-8"
                style={{
                  height: "95vh",
                  borderTopLeftRadius: 40,
                  borderTopRightRadius: 40,
                }}
                // onScrolledBottom={() => {
                //   let length = cloneDeep(page);
                //   setPage(length + 1);
                // }}
              >
                {map(questions_types, (item, index) => {
                  let dataSource = get(item, "dataSource");
                  return (
                    <div className="mt-8">
                      <div className="text-xl break-words pl-6  font-bold">
                        <span>{item.type}</span>
                      </div>
                      <Collapse
                        className=""
                        expandIconPosition="right"
                        style={{
                          borderRadius: 30,
                          backgroundColor: "#20262f",
                          paddingBottom: 10,
                          margin: 5,
                          color: "white",
                        }}
                      >
                        {map(dataSource, (item, index) => {
                          return (
                            <>
                              <Panel
                                header={
                                  <div className="font-bold text-white">
                                    {`${index + 1}. ${get(item, "questions")}`}
                                  </div>
                                }
                                key={index + 1}
                                className={`border-2 border-slate-400 mb-2 mx-2 rounded-full ${
                                  index === 0 ? "mt-4" : ""
                                }`}
                                style={{
                                  borderRadius: 30,
                                  backgroundColor: "#20262f",
                                  color: "white",
                                }}
                              >
                                <div className="m-2 ">
                                  <div className="break-words text-white">
                                    {item.answer()}
                                  </div>
                                </div>
                              </Panel>
                            </>
                          );
                        })}
                      </Collapse>
                    </div>
                  );
                })}
              </Scrollbars> */}
            </div>
            <FaqDrawer
              visible={visible}
              data={selectedType}
              onClose={() => {
                setVisible(false);
                setSelectedType({});
              }}
            />
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosListingPage);
