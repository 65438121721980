import * as React from "react";
import SharedHeader from "../components/general/components/SharedHeader";
import FaqListing from "../components/faq/pages/FaqListing";

// markup
const Profile = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <FaqListing />
    </React.Fragment>
  );
};

export default Profile;
