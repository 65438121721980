import { Drawer, Spin } from "antd";
import { map } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// markup
const VideosListingPage = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setVisible(props.visible === true);
  }, [props.visible]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  //   useEffect(() => {
  //     getData((1 - 1) * PAGESIZE);
  //   }, [languageType]);

  function getData(skip) {
    setLoading(true);
    // console.log("getData");
  }

  // console.log(data);

  function close() {
    setVisible(false);
    setData({});
    if (props.onClose) {
      props.onClose();
    }
  }

  return (
    <React.Fragment>
      <Drawer
        visible={visible}
        width="100%"
        className="body-no-padding"
        closable={false}
        onClose={() => {
          close();
        }}
      >
        <Layout>
          <Spin spinning={loading}>
            <NavHeader
              suffix={
                <div className="justify-end flex items-center">
                  {/* FAQ{" "} */}
                  {t("faq")}
                  {/* <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery>
                  常问问题 */}
                </div>
              }
              onBack={() => {
                close();
              }}
              showBack={true}
            >
              <div
                className="mx-4"
                // style={{ borderTopLeftRadius: 40, borderTopRightRadius: 40 }}
              >
                <div className="mb-2 text-base font-bold">
                  {get(data, "type")}
                </div>

                <div className="mx-2 greybox-bg-color rounded-3xl py-2 px-6">
                  {map(get(data, "dataSource"), (item, index) => {
                    return (
                      <div className="mb-4">
                        <div className="pb-1 font-semibold">
                          {index + 1}. {get(item, "questions")}
                        </div>
                        <div>{item.answer()}</div>
                      </div>
                    );
                  })}
                </div>

                {/* {map(questions_types, (item, index) => {
                  return (
                    <div className="lightgreybox-bg-color px-4 py-2.5 m-4 rounded-3xl ">
                      {get(item, "type")}
                    </div>
                  );
                })} */}
              </div>
            </NavHeader>
          </Spin>
        </Layout>
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideosListingPage);
